<template>
	<div>
		<v-subheader class="title">Leveransadress</v-subheader>
		<template v-if="!deliveryAddress">
			<v-alert type="error" outlined prominent border="left">
				Du saknar en leveransadress
			</v-alert>
		</template>
		<template v-else>
			<a-account-address-card :address="deliveryAddress" />
		</template>

		<v-subheader class="title">Fakturaadress</v-subheader>
		<template v-if="!invoiceAddress">
			<v-alert type="error" outlined prominent border="left" class="mb-2">
				Du saknar en fakturaadress
			</v-alert>
		</template>
		<template v-else>
			<a-account-address-card :address="invoiceAddress" />
		</template>

		<v-subheader v-if="otherAddresses.length > 0" class="title">
			Övriga adresser
		</v-subheader>
		<a-account-address-card
			v-for="(otherAddress, index) in otherAddresses"
			:key="index"
			:address="otherAddress"
		/>
	</div>
</template>

<script>
import AAccountAddressCard from '@/components/AccountAddressCard';

export default {
	components: {
		AAccountAddressCard,
	},
	props: {
		addresses: {
			type: Array,
			required: true,
		},
	},
	computed: {
		deliveryAddress() {
			return this.addresses
				.filter(address => address.isDelivery && !this.addressMissing(address))
				.pop();
		},
		invoiceAddress() {
			return this.addresses
				.filter(address => address.isInvoice && !this.addressMissing(address))
				.pop();
		},
		otherAddresses() {
			return this.addresses.filter(
				address => !address.isInvoice && !address.isDelivery,
			);
		},
	},
	methods: {
		addressMissing(address) {
			return (
				address.street === '' && address.city === '' && address.areaCode === ''
			);
		},
	},
};
</script>

<style></style>
