<template>
	<v-card v-if="addressExists" class="mb-2" style="width: 100%">
		<v-list>
			<v-list-item :href="mapsUrl" two-line>
				<v-list-item-icon class="mt-auto mb-auto mr-4">
					<v-icon color="secondary">mdi-map-marker</v-icon>
				</v-list-item-icon>

				<v-row class="mx-0">
					<v-col class="pa-0">
						<v-list-item-content>
							<v-list-item-title>{{ address.street }}</v-list-item-title>
							<v-list-item-subtitle>{{ areaCodeAndCity }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-col>

					<v-col v-if="address.portCode" class="pa-0">
						<v-list-item-content class="text-right">
							<v-list-item-title>{{ address.portCode }}</v-list-item-title>
							<v-list-item-subtitle>Portkod</v-list-item-subtitle>
						</v-list-item-content>
					</v-col>
				</v-row>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import addressMixin from '@/mixins/addressMixin';

export default {
	mixins: [addressMixin],
	props: {
		address: {
			type: Object,
			required: true,
		},
	},
	computed: {
		areaCodeAndCity() {
			return this.$_addressMixin_formatAreaCodeAndCity(
				this.address.areaCode,
				this.address.city,
			);
		},
		mapsUrl() {
			const areaCode = this.address.areaCode.replace(' ', '');
			return `http://maps.google.co.in/maps?q=${this.address.street},${areaCode},${this.address.city},Sweden`;
		},
	},
	methods: {
		addressExists() {
			return (
				(this.address && this.address.isDelivery) ||
				this.address.isInvoice ||
				(!this.address.isDelivery && !this.address.isInvoice)
			);
		},
	},
};
</script>

<style></style>
