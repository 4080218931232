var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex-column flex-nowrap align-self-start"},[_c('apollo-query',{attrs:{"query":_vm.query,"update":function (data) { return ({
				client: data.me.client,
				useHourpot: data.company.useHourpot,
			}); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var ref_result = ref.result;
			var loading = ref_result.loading;
			var data = ref_result.data;
return [(data)?_c('div',[_c('a-account-info-card',{attrs:{"account":data.client,"use-hourpot":data.useHourpot}}),_c('a-account-addresses-card',{attrs:{"addresses":data.client.addresses}}),(data.client.contacts.length > 0)?_c('v-subheader',{staticClass:"title"},[_vm._v(" Hushållet ")]):_vm._e(),_vm._l((data.client.contacts),function(contact,index){return _c('a-account-info-card',{key:(index + "-contact"),attrs:{"account":contact,"secondary":""}})})],2):_c('div',[_c('a-loading',{attrs:{"loading":loading,"type":"card-heading, list-item@3"}}),_c('a-loading',{attrs:{"loading":loading,"type":"card-heading, list-item-two-line, list-item@2"}})],1)]}}])}),_c('v-btn',{staticClass:"my-3",attrs:{"color":"secondary","block":"","large":""},on:{"click":function($event){return _vm.showCookiePopup()}}},[_vm._v(" Uppdatera cookieinställningar ")]),_c('v-btn',{attrs:{"loading":_vm.loggingOut,"disabled":_vm.loggingOut,"color":"error","block":"","large":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-logout")]),_vm._v("Logga ut ")],1),_c('apollo-query',{attrs:{"query":_vm.fabQuery,"update":function (data) { return data.issueTypes; },"variables":{ input: { view: 'account' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var ref_result = ref.result;
			var loading = ref_result.loading;
			var data = ref_result.data;
return [(data)?_c('a-create-issue-fab',{attrs:{"issue-types":data}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }