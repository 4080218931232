<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query
			:query="query"
			:update="
				data => ({
					client: data.me.client,
					useHourpot: data.company.useHourpot,
				})
			"
		>
			<template #default="{ result: { loading, data } }">
				<div v-if="data">
					<a-account-info-card
						:account="data.client"
						:use-hourpot="data.useHourpot"
					/>

					<a-account-addresses-card :addresses="data.client.addresses" />

					<v-subheader v-if="data.client.contacts.length > 0" class="title">
						Hushållet
					</v-subheader>

					<a-account-info-card
						v-for="(contact, index) in data.client.contacts"
						:key="`${index}-contact`"
						:account="contact"
						secondary
					/>
				</div>
				<div v-else>
					<a-loading :loading="loading" type="card-heading, list-item@3" />
					<a-loading
						:loading="loading"
						type="card-heading, list-item-two-line, list-item@2"
					/>
				</div>
			</template>
		</apollo-query>
		<v-btn
			color="secondary"
			block
			large
			class="my-3"
			@click="showCookiePopup()"
		>
			Uppdatera cookieinställningar
		</v-btn>

		<v-btn
			:loading="loggingOut"
			:disabled="loggingOut"
			color="error"
			block
			large
			@click="logout()"
		>
			<v-icon class="pr-2">mdi-logout</v-icon>Logga ut
		</v-btn>

		<apollo-query
			:query="fabQuery"
			:update="data => data.issueTypes"
			:variables="{ input: { view: 'account' } }"
		>
			<template #default="{ result: { loading, data } }">
				<a-create-issue-fab v-if="data" :issue-types="data" />
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import ALoading from '@/components/Loading';
import AAccountInfoCard from '@/components/AccountInfoCard';
import AAccountAddressesCard from '@/components/AccountAddressesCard';
import ACreateIssueFab from '@/components/CreateIssueFab';
import { ME, ISSUE_CREATION_TYPES } from '@/graphql';

export default {
	name: 'Account',
	components: {
		ALoading,
		AAccountInfoCard,
		AAccountAddressesCard,
		ACreateIssueFab,
	},
	data: () => ({
		query: ME,
		fabQuery: ISSUE_CREATION_TYPES,
		loggingOut: false,
	}),
	methods: {
		logout() {
			this.loggingOut = true;
			this.$router.push({ name: 'logout' });
		},
		showCookiePopup() {
			this.$root.$emit('update-cookie-settings');
		},
	},
	metaInfo: {
		title: 'Mitt konto',
	},
};
</script>

<style></style>
