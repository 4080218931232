<template>
	<v-card
		:class="{ 'mb-2': true, 'white--text': !secondary }"
		:color="secondary ? '' : 'primary'"
	>
		<v-card-title v-if="!noName">{{ account.name }}</v-card-title>
		<v-card-subtitle :class="{ 'white--text': !secondary }">
			<template v-if="account.pnr">
				{{ account.pnr }}
				<br />
			</template>
		</v-card-subtitle>

		<v-card-text
			:class="{ 'white--text': !secondary }"
			v-if="account.phoneNumbers.phone || account.phoneNumbers.mobile"
		>
			<v-icon class="pr-2">mdi-email</v-icon>
			{{ account.email }}
			<br />
			<template v-if="account.phoneNumbers.phone">
				<v-icon class="pr-2">mdi-phone</v-icon>
				{{ account.phoneNumbers.phone }}
				<br />
			</template>
			<template v-if="account.phoneNumbers.mobile">
				<v-icon class="pr-2">mdi-cellphone-android</v-icon>
				{{ account.phoneNumbers.mobile }}
			</template>
		</v-card-text>
		<v-card-text :class="{ 'white--text': !secondary }" v-if="useHourpot">
			Timbankssaldo: {{ formatHours(account.hourpot) }} timmar
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'AccountInfoCard',
	props: {
		secondary: {
			type: Boolean,
		},
		noName: {
			type: Boolean,
		},
		account: {
			type: Object,
			required: true,
		},
		useHourpot: {
			type: Boolean,
		},
	},
	methods: {
		formatHours: r =>
			new Intl.NumberFormat('se-SE', {
				signDisplay: 'exceptZero',
				maximumFractionDigits: 2,
			}).format(r),
	},
};
</script>

<style></style>
