const addressMixin = {
	methods: {
		$_addressMixin_formatAreaCodeAndCity(areaCode, city) {
			// Formats areaCode and city to format(111 11, City)
			if (!(areaCode && city)) {
				return city;
			}

			if (areaCode[3] === ' ') {
				return `${areaCode}, ${city}`;
			}

			return `${areaCode.slice(0, 3)} ${areaCode.slice(3, 5)},  ${city}`;
		},
	},
};

export default addressMixin;
